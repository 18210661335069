import React from 'react';
import {TiSocialTwitterCircular,TiSocialFacebookCircular,TiSocialInstagramCircular} from 'react-icons/ti';
import {MdLocationOn} from 'react-icons/md'
import CopyrightIcon from '@material-ui/icons/Copyright';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Zoom from '@material-ui/core/Zoom';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import axios from '../../axios';
import clsx from 'clsx';
import './Footer.css';
import Logo from "../../assets/images/Light_house.svg"


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

function ScrollHome(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-home');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

function ScrollWork(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-work');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}
function ScrollDonate(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-donate');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

function ScrollGallery(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-gallery');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollWork.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}

ScrollHome.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}
ScrollDonate.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}
ScrollGallery.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}

function Footer(props) {
  const [email, setEmail] = React.useState('');

  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleSubscribe = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      if (email !== '') {
        axios.get(`/api/subscribe/${email}`).
        then(response => {      
          console.log('response',response);     
            setEmail('')
            setSuccess(true);
            setLoading(false);
        }).catch(err=>{
          console.log("err",err);
        });       
        }
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  return (
    <footer>
      <Row className="footer-top">
        <Col xs={12} sm={12} md={2} lg={2} xl={2} className="footer-logo-area">
          <ul>
            <li className="trust-logo" style={{textAlign:'end'}}>
              <img alt="Light house trust" src={Logo}></img>
            </li>
            <li className="social-media">
              <TiSocialFacebookCircular color="black" size="2em" />
              <TiSocialInstagramCircular color="black" size="2em"/>
              <TiSocialTwitterCircular color="black" size="2em"/>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} xl={4} className="footer-address-area">
          <ul>     
            <li><h6>Address</h6></li>            
            <li><MdLocationOn size="1.5em"/>
            No.2, 2nd, Street Shanthi Puram,
            <br/>Near Thirumullaivoyal Bus stop,</li>
            {/* <li> Uthukottai Taluk,</li> */}
            <li>Thirumullaivoyal,Chennai. 600062</li>
            <li><a href="mailto:lighthousetrust2018@gmail.com">
              <DraftsOutlinedIcon /> lighthousetrust2018@gmail.com
                  </a></li>
            <li><a href="tel: 7825923238">
              <PhoneAndroidOutlinedIcon /> +91 7825923238
                  </a></li>
            <li><a href="tel: 04435505783">
              <PhoneAndroidOutlinedIcon /> 044 3550 5783
                  </a></li>
          </ul>
        </Col>
        <Col xs={12} sm={6} md={3} lg={3} xl={3} className="footer-links-area">
          <ul>
            <li> <h6>Quick Links</h6> </li>
            <li><ScrollHome {...props}> Home </ScrollHome></li>
            <li><ScrollDonate {...props}>  Donate </ScrollDonate></li>
            <li><ScrollWork {...props}>  Works </ScrollWork></li>
            <li><ScrollGallery {...props} >Gallery</ScrollGallery></li>
          </ul>
        </Col>
        <Col xs={12} sm={6} md={3} lg={3} xl={3} className="footer-news-area">
          <Form>
            <h6>News letter</h6>
            <Form.Group>             
              <Form.Control type='email' placeholder="Enter email" value={email} onChange={(event) => handleEmailChange(event)} />
            </Form.Group>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                <Button
                  id='subscribeButton'
                  variant="contained"
                  color="primary"
                  className={buttonClassname}
                  disabled={loading || !email}
                  onClick={handleSubscribe}
                  style={{ backgroundColor: "#ffffff" }}
                >
                  Subscribe
                     </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </div>
          </Form>
        </Col>
      </Row>
      <div className="footer-bottom">
        <Row >
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className='copyrights' >
            Lighthouse <CopyrightIcon /> 2020 All Rights Reserved.
              </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className="techotron" >
            <span>Made with</span> <a href="https://www.techotron.in" target="_blank">Techotron</a>
          </Col>
        </Row>
      </div>
    </footer>
  );
}

export default Footer;
