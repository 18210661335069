import React from 'react';
// import AppsIcon from '@material-ui/icons/Apps';
import Slideimg1 from '../../assets/images/slideimg1.jpg'
import Slideimg2 from '../../assets/images/slideimg2.jpg'
import Slideimg3 from '../../assets/images/slideimg3.jpg'
import Slideimg4 from '../../assets/images/slideimg4.jpg'
import Slideimg5 from '../../assets/images/slideimg5.jpg'
import Slideimg6 from '../../assets/images/slideimg6.jpg'
import Slideimg7 from '../../assets/images/slideimg7.jpg'
import Slideimg8 from '../../assets/images/Training2.JPG'
import { Row, Col, Button } from 'react-bootstrap';
import { BsArrowRight , BsArrowLeft } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
// import HomeBG from "../../assets/images/Top_Banner.jpg";
import './Home.css';

function Home(props) {

    const handleDonateScroll = () => {
        var elmnt = document.getElementById("back-to-donate");
        elmnt.scrollIntoView();
    }

    const images = [
        { url: Slideimg1 },
        { url: Slideimg2 },
        { url: Slideimg3 },
        { url: Slideimg4 },
        { url: Slideimg5 },
        { url: Slideimg6 },
        { url: Slideimg7 },
        { url: Slideimg8 }
    ];
    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        bottom: 'calc(25% - 15px)',
        width: 50,
        height: 45,
        cursor: 'pointer',
    };

    return (
        <>
            <Row id="back-to-home" className="home-row">
                {/* <img className="home-background" src={HomeBG} alt="home-background"/> */}
                <Col className="home-col" xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className="text-content">
                        <p>T O G E T H E R</p>
                        <h1>Keep India's vulnerable</h1>
                        <h1>people safe</h1>
                        <p className="text-quote">Happiness Doesn't Result From What We Get, But From What We Give.</p>
                        <Button className='donate-home-btn' variant='primary' type='button' onClick={() => handleDonateScroll()}>Donate</Button>
                    </div>
                </Col>
                <Col className="home-img-col" xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Carousel dynamicHeight={false} 
                     renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                            <button className="direction-icon" type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
                                <BsArrowLeft color="white" size="2em"/>
                            </button>
                        )
                    }
                     renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                            <button className="direction-icon" type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                                <BsArrowRight color="white" size="2em"/>
                            </button>
                        )
                    }>
                        {
                            images.map((image, i) => {
                                return (

                                    <img key={i} src={image.url} alt={i} />

                                )
                            })
                        }
                    </Carousel>
                </Col>
            </Row>
        </>
    );
}

export default Home;
