import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import $ from 'jquery';
import './Gallery.css';

function Gallery() {
    const [isGallery, setIsGallery] = useState(false);
    const [activeGallery, setActiveGallery] = useState(0);
    const handleViewAll = () => {
        setIsGallery(!isGallery);
    };
    const galleries = [
        { key: 'all', name: 'All' },
        { key: '1', name: 'Covid - 19' },
        { key: '2', name:  `Children's Education` },
        { key: '3', name: 'Women Empowerment' },
        { key: '4', name: 'Help Handicapped' },
        { key: '5', name: 'Old age Assistance' },
        { key: '6', name: 'Social Welfare' },
        { key: '7', name: 'Training' },
    ]
    const handleGalleryFilter = (i) => {
        // setActiveGallery(i);
        $(function () {
            var selectedClass = "";
            $(".filter").click(function () {
                selectedClass = $(this).attr("data-rel");
                $("#gallery").fadeTo(100, 0.1);
                $("#gallery div").not("." + selectedClass).fadeOut().removeClass('animation');
                setTimeout(function () {
                    $("." + selectedClass).fadeIn().addClass('animation');
                    $("#gallery").fadeTo(300, 1);
                }, 300);
            });
        });
    }
    const nextGallery = (i) => {
        if (i >= 0 && i < galleries.length) {
            setActiveGallery(i);
        }
    }
    useEffect((() => {
        $(function () {
            var selectedClass = "";
            $(".filter").click(function () {
                selectedClass = $(this).attr("data-rel");
                $("#gallery").fadeTo(100, 0.1);
                $("#gallery div").not("." + selectedClass).fadeOut().removeClass('animation');
                setTimeout(function () {
                    $("." + selectedClass).fadeIn().addClass('animation');
                    $("#gallery").fadeTo(300, 1);
                }, 300);
            });
        });
    }), [activeGallery])
    return (
        <Container fluid={true} id="back-to-gallery" style={{marginBottom:'5%'}}>
            <div className="gallery-divider"></div>
            <div className="gallery-header">
                <h1>Gallery & Videos</h1>
            </div>
            {!isGallery ?
                <div className="gallery-img" onClick={() => handleViewAll()}></div>
                :
                <div >
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="gallery-buttons">
                            {galleries.map((gallery, i) => {
                                if (i === activeGallery) {
                                    return (
                                        <button type="button" className="gallery-labels  filter" data-rel={gallery.key} onClick={handleGalleryFilter(i)}>{gallery.name}</button>
                                    )
                                }
                                return null;
                            })}
                            <button disabled={activeGallery === 0 ? true : false} data-rel={activeGallery - 1 === 0 ? 'all' : activeGallery - 1} 
                            className={activeGallery === 0 ? "direction-icon disable-direction-icon filter" : "direction-icon filter"} 
                            onClick={() => nextGallery(activeGallery - 1)}>
                                <BsArrowLeft color="white" size="2em" />
                            </button>
                            <button disabled={activeGallery === galleries.length - 1 ? true : false} data-rel={activeGallery + 1} 
                            className={activeGallery === galleries.length - 1 ? "direction-icon disable-direction-icon filter" : "direction-icon filter"} 
                            onClick={() => nextGallery(activeGallery + 1)}>
                                <BsArrowRight color="white" size="2em" />
                            </button>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="gallery-buttons">
                            <button type="button" className="btn btn-outline-black waves-effect filter-btn" onClick={() => handleViewAll()} >Back</button>
                        </Col>
                    </Row>
                    <div className="gallery" id="gallery">
                        <div className="mb-3 pics animation all 1">
                            <img className="img-fluid" src={require("../../assets/images/Covid1.jpg")} alt="Covid1"></img>
                        </div>
                        <div className="mb-3 pics animation all 1">
                            <img className="img-fluid" src={require("../../assets/images/Covid2.jpg")} alt="Covid2"></img>
                        </div>
                        <div className="mb-3 pics animation all 1">
                            <img className="img-fluid" src={require("../../assets/images/Covid3.jpg")} alt="Covid3"></img>
                        </div>
                        <div className="mb-3 pics animation all 1">
                            <img className="img-fluid" src={require("../../assets/images/Covid4.jpg")} alt="Covid4"></img>
                        </div>
                        <div className="mb-3 pics animation all 1">
                            <img className="img-fluid" src={require("../../assets/images/Covid5.jpg")} alt="Covid5"></img>
                        </div>
                        <div className="mb-3 pics animation all 1">
                            <img className="img-fluid" src={require("../../assets/images/Covid6.jpg")} alt="Covid6"></img>
                        </div>
                        <div className="mb-3 pics animation all 1">
                            <img className="img-fluid" src={require("../../assets/images/Covid13.jpg")} alt="Covid7"></img>
                        </div>
                        <div className="mb-3 pics animation all 1">
                            <img className="img-fluid" src={require("../../assets/images/Covid8.jpg")} alt="Covid8"></img>
                        </div>
                        <div className="mb-3 pics animation all 1">
                            <img className="img-fluid" src={require("../../assets/images/Covid9.jpg")} alt="Covid9"></img>
                        </div>
                        <div className="mb-3 pics animation all 1">
                            <img className="img-fluid" src={require("../../assets/images/Covid10.jpg")} alt="Covid10"></img>
                        </div>
                        <div className="mb-3 pics animation all 1">
                            <img className="img-fluid" src={require("../../assets/images/Covid11.jpg")} alt="Covid11"></img>
                        </div>
                        <div className="mb-3 pics animation all 1">
                            <img className="img-fluid" src={require("../../assets/images/Covid14.jpg")} alt="Covid12"></img>
                        </div>
                        <div className="mb-3 pics animation all 2">
                            <img className="img-fluid" src={require("../../assets/images/children01.JPG")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 2">
                            <img className="img-fluid" src={require("../../assets/images/children02.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 2">
                            <img className="img-fluid" src={require("../../assets/images/children03.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 2">
                            <img className="img-fluid" src={require("../../assets/images/children04.JPG")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 2">
                            <img className="img-fluid" src={require("../../assets/images/children05.JPG")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 2">
                            <img className="img-fluid" src={require("../../assets/images/children06.JPG")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 2">
                            <img className="img-fluid" src={require("../../assets/images/children07.JPG")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 2">
                            <img className="img-fluid" src={require("../../assets/images/children08.JPG")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 2">
                            <img className="img-fluid" src={require("../../assets/images/children09.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 2">
                            <img className="img-fluid" src={require("../../assets/images/children10.JPG")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 2">
                            <img className="img-fluid" src={require("../../assets/images/children01.JPG")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 3">
                            <img className="img-fluid" src={require("../../assets/images/women01.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 3">
                            <img className="img-fluid" src={require("../../assets/images/women02.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 3">
                            <img className="img-fluid" src={require("../../assets/images/women03.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 3">
                            <img className="img-fluid" src={require("../../assets/images/women04.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 3">
                            <img className="img-fluid" src={require("../../assets/images/women05.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 3">
                            <img className="img-fluid" src={require("../../assets/images/women01.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 4">
                            <img className="img-fluid" src={require("../../assets/images/handicapped01.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 4">
                            <img className="img-fluid" src={require("../../assets/images/handicapped02.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 4">
                            <img className="img-fluid" src={require("../../assets/images/handicapped03.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 5">
                            <img className="img-fluid" src={require("../../assets/images/old01.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 5">
                            <img className="img-fluid" src={require("../../assets/images/old02.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 5">
                            <img className="img-fluid" src={require("../../assets/images/old03.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 5">
                            <img className="img-fluid" src={require("../../assets/images/old04.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation  5">
                            <img className="img-fluid" src={require("../../assets/images/women05.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation  5">
                            <img className="img-fluid" src={require("../../assets/images/women03.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 6">
                            <img className="img-fluid" src={require("../../assets/images/social01.JPG")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 6">
                            <img className="img-fluid" src={require("../../assets/images/social02.JPG")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 6">
                            <img className="img-fluid" src={require("../../assets/images/social02.JPG")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 6">
                            <img className="img-fluid" src={require("../../assets/images/social02.JPG")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation  6">
                            <img className="img-fluid" src={require("../../assets/images/women05.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation  6">
                            <img className="img-fluid" src={require("../../assets/images/women03.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 7">
                            <img className="img-fluid" src={require("../../assets/images/Training1.JPG")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 7">
                            <img className="img-fluid" src={require("../../assets/images/Training2.JPG")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 7">
                            <img className="img-fluid" src={require("../../assets/images/Training5.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation all 7">
                            <img className="img-fluid" src={require("../../assets/images/Training6.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation  7">
                            <img className="img-fluid" src={require("../../assets/images/Training3.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation  7">
                            <img className="img-fluid" src={require("../../assets/images/Training4.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation  7">
                            <img className="img-fluid" src={require("../../assets/images/Training7.jpg")} alt="Card  cap"></img>
                        </div>
                        <div className="mb-3 pics animation  7">
                            <img className="img-fluid" src={require("../../assets/images/Training8.jpg")} alt="Card  cap"></img>
                        </div>
                    </div>
                </div>
            }
        </Container >
    );
}

export default Gallery;
