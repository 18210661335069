import React,{useState} from 'react';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import *as ReactBootstrap from 'react-bootstrap';
import {Container} from 'react-bootstrap'
import Logo from "../../assets/images/Light_house.svg"
import './NavBar.css';
  
function NavBar() {
    const[selectedMenu, setSelectedMenu] = useState('home')
    const handleHomeScroll = () => {
        setSelectedMenu('home')
        var elmnt = document.getElementById("back-to-home");
        console.log('elmnt',elmnt);
        elmnt.scrollIntoView();
    }

    const handleWorkScroll = () => {
        setSelectedMenu('works')
        var elmnt = document.getElementById("back-to-work");
        console.log('elmnt',elmnt);
        elmnt.scrollIntoView();
    }

    const handleGalleryScroll = () => {
        setSelectedMenu('gallery')
        var elmnt = document.getElementById("back-to-gallery");
        elmnt.scrollIntoView();
    }

    const handleDonateScroll = () => {
        var elmnt = document.getElementById("back-to-donate");
        elmnt.scrollIntoView();
    }

    
    return (
        <Container fluid={true}  className="navbar-top" >        
        <ReactBootstrap.Navbar sticky="top" collapseOnSelect expand="md" bg="white" variant="light">
        <ReactBootstrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <ReactBootstrap.Navbar.Brand >
                <div className="trust-logo">
                    <img alt="Light house trust" src={Logo}></img>
                </div>
                </ReactBootstrap.Navbar.Brand>         
            <ReactBootstrap.Navbar.Collapse id="responsive-navbar-nav">
                <ReactBootstrap.Nav className="mx-auto w-100">
                    <ReactBootstrap.Nav.Link onClick={() => handleHomeScroll()} className={selectedMenu ==='home'?"nav-link-active":''}>Home</ReactBootstrap.Nav.Link>
                    <ReactBootstrap.Nav.Link onClick={() => handleWorkScroll()} className={selectedMenu ==='works'?"nav-link-active":''}>Works</ReactBootstrap.Nav.Link>
                    <ReactBootstrap.Nav.Link onClick={() => handleGalleryScroll()} className={selectedMenu ==='gallery'?"nav-link-active":''}>Gallery</ReactBootstrap.Nav.Link>               
                    <ReactBootstrap.Nav.Link href="mailto:lighthousetrust2018@gmail.com">
                        <DraftsOutlinedIcon /> lighthousetrust2018@gmail.com
                    </ReactBootstrap.Nav.Link>
                    <ReactBootstrap.Nav.Link  href="tel:+ 7825923238">
                        <PhoneAndroidOutlinedIcon /> +91 7825923238
                    </ReactBootstrap.Nav.Link>
                    <ReactBootstrap.Nav.Link>                    
                    <ReactBootstrap.Button className="donateButton" type="button" onClick={() => handleDonateScroll()}>Donate</ReactBootstrap.Button>                   
                    </ReactBootstrap.Nav.Link>
                </ReactBootstrap.Nav>
            </ReactBootstrap.Navbar.Collapse>
        </ReactBootstrap.Navbar>
        </Container>
    );
}

export default NavBar;
