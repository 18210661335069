import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import *as ReactBootstrap from 'react-bootstrap';
import { Row, Col, Button} from 'react-bootstrap';
import axios from '../../axios';
import './Donate.css'


function loadScript(src) {
    return new Promise(resolve => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })

}
const _DEV_ = document.domain === 'localhost';
function Donate() {
    const [value, setValue] = useState('Covid - 19');
    const [donationAmount, setDonationAmount] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [contact, setContact] = useState('');
    const [email,setEmail] = useState('');

    const handleDonationAmountChange = (event) => {
        setDonationAmount(event.target.value)
    }

    const handleNameChange = (event) => {
        setName(event.target.value)
    }

    const handleAddressChange = (event) => {
        setAddress(event.target.value)
    }

    const handleContactChange = (event) => {
        setContact(event.target.value)
    }

    const handleMailChange = (event) => {
        setEmail(event.target.value)
    }
    const handleChange = (event) => {
        setValue(event.target.value);
    };


    const handleDonationAmoutEmail = () => {
        const body = JSON.stringify(
            {
                donationAmount: donationAmount,
                name: name,
                address: address,
                contact: contact,
                email: email,
                value:value
            })
        axios.post('/api/donate',body,{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response=>{
            console.log('response',response.data)
        })
        // fetch(`api/donate`, { method: 'POST', 
        // headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json'
        // },
        // body: JSON.stringify(
        //     {
        //         donationAmount: donationAmount,
        //         name: name,
        //         address: address,
        //         contact: contact,
        //         email: email,
        //         value:value
        //     }) })
        // .then(res => res)
    }

    async function handleDonateNow() {
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js")
        if (!res) {
            alert("Not in Online")
            return
        }
        await axios.post('/api/razorpay',{amount: donationAmount},{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
        }).then(res=>{
            console.log('res',res.data);
            const data = res.data;
            const options = {
                "key": _DEV_ ? "rzp_live_o8PGSfsKgIGyNM" : "rzp_live_o8PGSfsKgIGyNM",
                "amount": data.amount.toString(),
                "currency": data.currency,
                "name": name,
                "description": "Donation for Light house trust",
                "image": "https://lighthouse-trust-api.herokuapp.com/logo.jpg",
                "order_id": data.id,
                "handler": function (response) {
                    console.log(response)
                    handleDonationAmoutEmail();
                },
                "prefill": {
                    "email": email
                }
            };
            var paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }).catch(err=>{
            console.log("err",err);
          });
        // const data = await fetch(`api/razorpay`, { method: 'POST', 
        // headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify({amount: donationAmount}) }).then(res => res.json())
        // console.log(data)

        // const options = {
        //     "key": _DEV_ ? "rzp_live_o8PGSfsKgIGyNM" : "rzp_live_o8PGSfsKgIGyNM",
        //     "amount": data.amount.toString(),
        //     "currency": data.currency,
        //     "name": name,
        //     "description": "Donation for Light house trust",
        //     "image": "https://lighthousetrust.in/logo.jpg",
        //     "order_id": data.id,
        //     "handler": function (response) {
        //         console.log(response)
        //         handleDonationAmoutEmail();
        //     },
        //     "prefill": {
        //         "email": email
        //     }
        // };
        // var paymentObject = new window.Razorpay(options);
        // paymentObject.open();
    }


    return (
        <Row id="back-to-donate" style={{ margin: 0 }}>
            <div className="donate-divider"></div>
            <div className="donate-header">
                <h1>Together with us today</h1>
                <p>We believe that the complex issues faced by our country can only be resolved when the state,
                  the civil society, individuals and corporates join hands, and work with each other</p>
            </div>
            <Row className="donation-card">
                <Col xs={12} sm={12} md={6} lg={6} xl={6} className="radio-option">
                    <h5>Make your Donation</h5>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="donationName" name="donationName" value={value} onChange={handleChange}>
                            <FormControlLabel value="Covid - 19" control={<Radio />} label="Covid - 19" />
                            <FormControlLabel value="Children's Education" control={<Radio />} label="Children's Education" />
                            <FormControlLabel value="Women Empowerment" control={<Radio />} label="Women Empowerment" />
                            <FormControlLabel value="Help Handicapped" control={<Radio />} label="Help Handicapped" />
                            <FormControlLabel value="Old age Assistance" control={<Radio />} label="Old age Assistance" />
                            <FormControlLabel value="Social Welfare" control={<Radio />} label="Social Welfare" />
                        </RadioGroup>
                    </FormControl>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} className="donation-option">
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ReactBootstrap.FormControl
                                aria-label="Default"                            
                                aria-describedby="inputGroup-sizing-default"
                                placeholder="Donation Amount*"
                                value={donationAmount}
                                onChange={(event) => handleDonationAmountChange(event)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ReactBootstrap.FormControl
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                placeholder="Name*"
                                value={name}
                                onChange={(event) => handleNameChange(event)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ReactBootstrap.FormControl
                                as="textarea"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                placeholder="Address*"
                                value={address}
                                onChange={(event) => handleAddressChange(event)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ReactBootstrap.FormControl
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                placeholder="Contact Phone*"
                                value={contact}
                                onChange={(event) => handleContactChange(event)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ReactBootstrap.FormControl
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                placeholder="Mail*"
                                value={email}
                                onChange={(event) => handleMailChange(event)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="donate-button">
                            
                                <form>
                                <script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_Fh85pTEiKeVWOB"> </script> 
                                </form>
                            
                            <Button type="button" block className="donateNowButton" onClick={() => handleDonateNow()} >
                            Donate Now  </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Row>
    );
}

export default Donate;
