
exports.worksContent = [{
    key:0,
    title:'Covid - 19',
    content:`Covid-19 has caused a massive change in our society. Join us in helping 
    health care workers fight from the frontlines with best-in-class safety equipment 
    and helping families affected by this outbreak with food and other opportunities.`,
    showMoreContent:``,
    img:require('../../assets/images/Covid1.jpg')
},
{
    key:1,
    title:`Children's Education`,
    content:`Today’s children are the building blocks of the country’s future. Help us 
    make a child’s future better, thereby making the future of the country brighter.`,
    showMoreContent:``,
    img:require('../../assets/images/children03.jpg')
},
{
    key:2,
    title:'Women Empowerment',
    content:`Women are crucial in the socio-economic and political progress of India. 
    Join us in empowering the women of our nation.`,
    showMoreContent:``,
    img:require('../../assets/images/women04.jpg')
},
{
    key:3,
    title:'Help Handicapped',
    content:`Handicapped people require special attention and care for a normal living. 
    Join us in making their lives easier.`,
    showMoreContent:``,
    img:require('../../assets/images/slideimg6.jpg')
},
{
    key:4,
    title:'Old age Assistance',
    content:`Old people are the pillar of our community. Their expertise helps in shaping generations. 
    Help us make their retirement years better and safer`,
    showMoreContent:``,
    img:require('../../assets/images/women05.jpg')
},
{
    key:5,
    title:'Social Welfare',
    content:`Social welfare aims to provide individuals and families in need. 
    Contribute now to make a difference in a person/family’s life.`,
    showMoreContent:``,
    img:require('../../assets/images/Covid11.jpg')
},
{
    key:6,
    title:'Training',
    content:`We are provide training for Tailoring Center, Beautician Center, Handicaped Skill Development, Self Help  Women's and youth Group and Free Tuition Center.`,
    showMoreContent:``,
    img:require('../../assets/images/Training2.JPG')
}
]
