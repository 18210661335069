import React,{useState,useEffect} from 'react';
import {Row,Col} from 'react-bootstrap';
import {worksContent} from './WorksContents'
import {BsArrowRight} from "react-icons/bs";
import OurWorks from '../../assets/images/Our_Works.jpg'
import './Work.css'

function Work() {
    const [value, setValue] = useState(0);

    const handleWorkChange = (value) => {
        if(value<worksContent.length)
        setValue(value);
    };

    // const handleReadMore = () => {
    //     var dots = document.getElementById("dots");
    //     var moreText = document.getElementById("more");
    //     var btnText = document.getElementById("myBtn");

    //     if (dots.style.display === "none") {
    //         dots.style.display = "inline";
    //         btnText.innerHTML = "Read more";
    //         moreText.style.display = "none";
    //     } else {
    //         dots.style.display = "none";
    //         btnText.innerHTML = "Read less";
    //         moreText.style.display = "inline";
    //     }
    // }
    useEffect((()=>{
        console.log('work content',worksContent)
    }),[])
    return (
        <Row id="back-to-work" className="works">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="divider">
                    <div className="work-divider"></div>
                    <h1>Our Works</h1>
            </Col>
                    <img src={OurWorks} alt="works" className="works-bg-img"/>
                <Col xs={12} sm={12} md={4} lg={4} xl={4} className="works-labels">                   
                    <div className="work-values">
                        {worksContent.map((ourwork,i)=>{
                           return(
                               <h6 key={i} className={value===i?"highlight-text":""} onClick={() => handleWorkChange(i)}>
                                   {ourwork.title}
                               </h6>
                           ) 
                        })}                        
                    </div>
                    <div className="work-values-responsive">
                        {worksContent.map((ourwork,i)=>{
                            if(i === value){
                                return(
                                <>
                                {i>0?
                                <h6 style={{color:"#c3c3c3"}} onClick={() => handleWorkChange(i-1)}>{worksContent[i-1].title}</h6>
                            :''}
                                    <h6 className={value===i?"highlight-text-responsive":""}onClick={() => handleWorkChange(i)}>
                                        {ourwork.title}
                                    </h6>
                                    {/* {i<worksContent.length-1?
                                <h6 style={{color:"#c3c3c3"}} onClick={() => handleWorkChange(i+1)}>{worksContent[i+1].title}</h6>
                            :''} */}
                                </>
                                ) 
                            }
                            return null;
                        })}  
                        <button disabled={value===worksContent.length-1?true:false}  className={value===worksContent.length-1?"direction-icon disable-direction-icon":"direction-icon"}>
                            <BsArrowRight color="#FF5456" size="2em" onClick={() => handleWorkChange(value+1)}/>
                            </button>                      
                    </div>
                </Col>
                 <Col  xs={12} sm={12} md={8} lg={8} xl={8} className="works-content">
                {worksContent.map((ourwork,i)=>{
                    if(value === i) {
                        return(
                         <div>
                         <div className="work-img">
                             <img src={ourwork.img} alt="slideimg"></img>
                         </div>
                         <div className="work-content">
                             <p>
                             {ourwork.content}
                             {/* <span id="dots">...
                                </span><span id="more">
                                {ourwork.showMoreContent}
                                </span>
                                <button className="read-button" onClick={handleReadMore} id="myBtn">Read more</button> */}
                             </p>
                         </div>
                         </div>
                        ) 
                    }
                   return null } )}                                   
                </Col>
        </Row>
    );
}

export default Work;
