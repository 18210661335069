import React from 'react';
import NavBar from './Component/NavBar/Navbar';
import Home from './Component/Home/Home';
import Donate from './Component/Donate/Donate';
import Work from './Component/Work/Work';
import Gallery from './Component/Gallery/Gallery';
import Footer from './Component/Footer/Footer'
import { Container } from 'react-bootstrap';
import './App.css';

function App() {
  return (
    <Container fluid={true} className="main-container">
       <NavBar />  
       <Home />
       <Donate />
       <Work />
       <Gallery />
       <Footer />
    </Container>
  );
}

export default App;
